import { useTheme } from 'next-themes';
import { useCallback } from 'react';

export const useCustomTheme = () => {
  const { theme, setTheme } = useTheme();

  const isDark = theme === 'dark';
  const toggleTheme = useCallback(() => {
    isDark ? setTheme('light') : setTheme('dark');
  }, [isDark, setTheme]);

  return {
    theme,
    isDark,
    toggleTheme,
  };
};
