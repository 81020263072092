import React from 'react';
import { SWRConfig } from 'swr';
import { axios } from './api';
import { useWeb3Context } from './Web3Context';

export function SWRProvider(props: { children: React.ReactNode }) {
  const { user } = useWeb3Context();

  return (
    <SWRConfig
      value={{
        fetcher: (url: string) => axios.get(url, { headers: user }).then(res => res.data),
      }}
    >
      {props.children}
    </SWRConfig>
  );
}
