const ONE_SECOND_IN_MS = 1000;
const ONE_MINUTE_IN_MS = ONE_SECOND_IN_MS * 60;
export const ONE_HOUR_IN_MS = ONE_MINUTE_IN_MS * 60;
export const ONE_DAY_IN_MS = ONE_HOUR_IN_MS * 24;

export const ONE_HOUR_IN_SECS = 60 * 60;
export const ONE_MINUTE_IN_SECONDS = 60;

export const MIN_WIDTH_SM = 640;
export const MIN_WIDTH_MD = 768;
export const MIN_WIDTH_LG = 1024;
export const MIN_WIDTH_XL = 1280;
export const MIN_WIDTH_2XL = 1536;

export const oneMonthAgo = Date.now() - 30 * ONE_DAY_IN_MS;

export function secondsToDays(timestampInSeconds: number) {
  return timestampInSeconds / 60 / 60 / 24;
}

export function msToSeconds(timestampInms: number) {
  return Math.floor(timestampInms / 1000);
}

// return a date in a YYYY-MM-DD format
export function toShortDateDaily(date: Date) {
  return new Date(date).toISOString().split('T')[0];
}

// return a date in a YYYY-MM-DD:HH format
export function toShortDateHourly(date: Date) {
  const splited = new Date(date).toISOString().split('T');
  const dailyDate = splited[0];
  const hour = splited[1].substring(0, 2);
  return `${dailyDate}:${hour}`;
}

// return all dates in between start and end including in a YYYY-MM-DD:HH format
export function getHoursArray(start: Date, end: Date) {
  const arr = [];

  for (
    let dt = start;
    dt <= end || toShortDateDaily(dt) === toShortDateDaily(end);
    dt.setDate(dt.getDate() + 1)
  ) {
    const shortDateDay = toShortDateDaily(dt);
    for (let i = 0; i < 24; i++) {
      const shortDateHour = toHour(i);
      arr.push(`${shortDateDay}:${shortDateHour}`);
    }
  }
  return arr;
}

export function ISODate(date: Date): Date {
  return new Date(date.toISOString());
}

function toHour(digit: number): string {
  if (digit < 10) return '0' + digit;
  return digit.toString();
}

export function prettyError(error: unknown) {
  // seems super strange. better way to print errors as json?
  return JSON.parse(JSON.stringify(error, Object.getOwnPropertyNames(error)));
}

export function isMobileDevice() {
  return (
    typeof window !== 'undefined' &&
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      window.navigator.userAgent
    )
  );
}

export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000';
