import React, { createContext, useContext, useState } from 'react';

interface Context {
  desktop: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  mobile: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

const defaultContextValue: Context = {
  desktop: [true, () => {}],
  mobile: [false, () => {}],
};

const MenuContext = createContext<Context>(defaultContextValue);

export const useMenuContext = () => useContext<Context>(MenuContext);

export const MenuContextProvider: React.FC = ({ children }) => {
  const [isOpenForMobile, setIsOpenForMobile] = useState(false);
  const [isOpenForDesktop, setIsOpenForDesktop] = useState(true);

  return (
    <MenuContext.Provider
      value={{
        mobile: [isOpenForMobile, setIsOpenForMobile],
        desktop: [isOpenForDesktop, setIsOpenForDesktop],
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
