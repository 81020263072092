import React from 'react';

import { XIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

import { InPortal } from './InPortal';

export interface ModalProps {
  hideModal: () => void;
  fullWidth?: boolean;
  size?: 'xl' | '4xl' | '6xl';
}

export function useModal() {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const openModal = React.useCallback(() => setIsModalOpen(true), []);
  const closeModal = React.useCallback(() => setIsModalOpen(false), []);

  return { isModalOpen, openModal, closeModal };
}

export const Modal: React.FC<ModalProps> = props => {
  if (typeof window === 'undefined') return null;

  return (
    <InPortal id="modal-root">
      <div className="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-6  h-full md:h-auto overflow-auto z-50 text-center">
        {/* <!--
    Background overlay, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  --> */}
        <div className="fixed inset-0 transition-opacity">
          {/* bg-black looks better? */}
          <div
            className="absolute inset-0 bg-gray-750 opacity-90 backdrop-blur"
            onClick={props.hideModal}
          ></div>
        </div>

        {/* <!--
    Modal panel, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      To: "opacity-100 translate-y-0 sm:scale-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100 translate-y-0 sm:scale-100"
      To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
  --> */}
        <div
          className={classNames(
            `bg-surface-light-mode dark:bg-surface-dark-mode dark:text-white rounded-xl px-4 pt-5 pb-4 mt-4 overflow-auto shadow-xl transform transition-all sm:p-6 inline-block align-middle text-left`,
            {
              'sm:max-w-xl sm:w-full': !props.fullWidth && (!props.size || props.size === 'xl'),
              'sm:max-w-6xl sm:w-full': !props.fullWidth && props.size === '6xl',
              'sm:max-w-4xl sm:w-full': !props.fullWidth && props.size === '4xl',
              'sm:max-w-full sm:w-full': props.fullWidth,
            }
          )}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <button className="absolute z-50 right-5 top-5" onClick={props.hideModal}>
            <XIcon className="w-6 h-6 text-gray-500 hover:text-black dark:text-gray-200 dark:hover:text-white" />
          </button>
          {props.children}
        </div>
      </div>
    </InPortal>
  );
};

interface ModalHeaderProps {
  title: string;
}

export const ModalHeader: React.FC<ModalHeaderProps> = props => {
  return (
    <h3 className="text-center text-lg leading-6 font-medium" id="modal-headline">
      {props.title}
    </h3>
  );
};

interface ModalDualButtonsProps {
  button1: React.ReactNode;
  button2: React.ReactNode;
}

export const ModalDualButtons: React.FC<ModalDualButtonsProps> = props => {
  return (
    <div className="sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
      <span className="flex w-full sm:col-start-2">{props.button1}</span>
      <span className="mt-3 flex w-full sm:mt-0 sm:col-start-1">{props.button2}</span>
    </div>
  );
};

interface ModalTripleButtonsProps {
  button1: React.ReactNode;
  button2: React.ReactNode;
  button3: React.ReactNode;
  vertical?: boolean;
}

export const ModalTripleButtons: React.FC<ModalTripleButtonsProps> = props => {
  if (props.vertical) return <div className="grid"></div>;

  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-3 sm:grid-flow-row-dense">
      <span className="flex w-full sm:col-start-3">{props.button1}</span>
      <span className="mt-3 flex w-full sm:mt-0 sm:col-start-2">{props.button2}</span>
      <span className="mt-3 flex w-full sm:mt-0 sm:col-start-1">{props.button3}</span>
    </div>
  );
};

interface ModalVerticalButtonsProps {
  buttons: React.ReactNode[];
}

export const ModalVerticalButtons: React.FC<ModalVerticalButtonsProps> = props => {
  return (
    <div className="grid gap-2">
      {props.buttons.map((button, i) => {
        return <div key={i}>{button}</div>;
      })}
    </div>
  );
};
