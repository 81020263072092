import Axios from 'axios';
import { UpsertAlertOptions } from '../pages/api/alert';
import { BASE_URL } from './config';
import { UserDetails } from './Web3Context';

export const axios = Axios.create({ baseURL: `${BASE_URL}` });

// const headers: { address?: string; signature?: string } = {
//   address: undefined,
//   signature: undefined,
// };

// axios.interceptors.request.use(
//   config => {
//     const address = headers.address || JSON.parse(localStorage.getItem('address') || '');
//     const signature = headers.signature || JSON.parse(localStorage.getItem('signature') || '');
//     if (address && signature && config.headers) {
//       config.headers.address = address;
//       config.headers.signature = signature;
//     }

//     return config;
//   },
//   error => {
//     return Promise.reject(error);
//   }
// );

// not working for some reason
export function updateSignature(options: { address?: string; signature?: string }) {
  const { address, signature } = options;
  axios.defaults.headers.common['address'] = address || '';
  axios.defaults.headers.common['signature'] = signature || '';
}

export async function upsertAlert(data: UpsertAlertOptions, headers: UserDetails) {
  return await axios.put(`/api/alert`, { data }, { headers });
}

export async function deleteAlert(alertId: number, headers: UserDetails) {
  return await axios.delete(`/api/alert/delete/${alertId}`, { headers });
}

export async function sendTestAlert(alertId: number, headers: UserDetails) {
  return await axios.get(`/api/alert/test/${alertId}`, { headers });
}
