function getBaseUrl(): string {
  if (!process.env.NEXT_PUBLIC_VERCEL_URL) return 'http://localhost:3000';

  if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') return `https://exodia.io`;

  return `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
}
export const BASE_URL = getBaseUrl();

export const BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL || 'http://localhost:3001';

export const DISABLE_PREMIUM = process.env.NEXT_PUBLIC_DISABLE_PREMIUM === 'true';
export const NETWORK_NAME = process.env.NEXT_PUBLIC_NETWORK_NAME || 'mainnet';

export const PURCHASE_CONTRACT_ADDRESS =
  NETWORK_NAME === 'mainnet' && process.env.NEXT_PUBLIC_PURCHASE_CONTRACT_ADDRESS
    ? process.env.NEXT_PUBLIC_PURCHASE_CONTRACT_ADDRESS
    : '0x54f3d69a19a8db554a85688704c7f19f4be111d3';

export const FIRE_EMBLEM_CONTRACT_ADDRESS =
  process.env.NEXT_PUBLIC_FIRE_EMBLEM_CONTRACT_ADDRESS ||
  '0xA3DD4e6711b71388d6D585DDcFBCfc0eeF42aF63';

export const THUNDER_EMBLEM_CONTRACT_ADDRESS =
  process.env.NEXT_PUBLIC_THUNDER_EMBLEM_CONTRACT_ADDRESS ||
  '0x11e7E09C331b711766Cd7eCBAa3C64803a358BE8';

export const FROST_EMBLEM_CONTRACT_ADDRESS =
  process.env.NEXT_PUBLIC_FROST_EMBLEM_CONTRACT_ADDRESS ||
  '0x1eD27A2BDD3B9d599Be0ddbE505822f0dF2D0d4B';

export const FLOW_EMBLEM_CONTRACT_ADDRESS =
  process.env.NEXT_PUBLIC_FLOW_EMBLEM_CONTRACT_ADDRESS ||
  '0xe7F22F0A662350Fb34321E03d5b3707681F22F97';

export const GALAXY_EMBLEM_CONTRACT_ADDRESS =
  process.env.NEXT_PUBLIC_GALAXY_EMBLEM_CONTRACT_ADDRESS || '';

export const WHALE_TOGETHER_CONTRACT_ADDRESS =
  process.env.NEXT_PUBLIC_WHALE_TOGETHER_CONTRACT_ADDRESS ||
  '0x417737d49a175d62625154262d8569d3890425ae';

export const INFURA_ID = process.env.NEXT_PUBLIC_INFURA_ID || '9171b6990eb5442c9ac0224115df03bd';
export const WHITELISTED_CONTRACTS = process.env.NEXT_PUBLIC_WHITELISTED_CONTRACTS
  ? process.env.NEXT_PUBLIC_WHITELISTED_CONTRACTS.replace("'", '').split(',')
  : [];
export const DISCOUNT_CONTRACTS = process.env.NEXT_PUBLIC_DISCOUNT_CONTRACTS
  ? process.env.NEXT_PUBLIC_DISCOUNT_CONTRACTS.replace("'", '').split(',')
  : [];
export const HOTJAR_ID = process.env.NEXT_PUBLIC_HOTJAR_ID;
export const AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY;
export const WRITE_DATABASE_URL = process.env.WRITE_DATABASE_URL;

export const PARTNERS_DISCOUNT_PRICE = Number(
  process.env.NEXT_PUBLIC_PARTNERS_DISCOUNT_PRICE || 0.4
);
export const SUBSCRIPTION_ADDRESS =
  process.env.NEXT_PUBLIC_SUBSCRIPTION_ADDRESS || '0x76CB3CF8EE2B2dD67832e6C78406Ab953Faba812';

export const ONE_MONTH_SUBSCRIPTION_PRICE = Number(
  process.env.NEXT_PUBLIC_ONE_MONTH_SUBSCRIPTION_PRICE || 0.08
);
export const SIX_MONTHS_SUBSCRIPTION_PRICE = Number(
  process.env.NEXT_PUBLIC_SIX_MONTHS_SUBSCRIPTION_PRICE || 0.4
);

export const GEM_API_KEY = process.env.GEM_API_KEY || '';
export const RESERVOIR_API_KEY = process.env.RESERVOIR_API_KEY || '';
export const OPENSEA_API_KEY = process.env.OPENSEA_API_KEY || '';
export const FIRE_EMBLEM_OS = 'https://opensea.io/collection/exodia-fire-emblem';
export const THUNDER_EMBLEM_OS = 'https://opensea.io/collection/exodia-thunder';
export const FROST_EMBLEM_OS = 'https://opensea.io/collection/exodia-frost';
export const FLOW_EMBLEM_OS = 'https://opensea.io/collection/exodia-flow';
export const GALAXY_EMBLEM_OS = 'https://opensea.io/collection/exodia-galaxy';
export const EXODIA_AFFILIATE_LINK = 'https://sharemint.xyz/exodia-premium/affiliate';
