import React from "react";
var __jsx = React.createElement;
import { ThemeProvider } from 'next-themes';
import NextNprogress from 'nextjs-progressbar';
import { NotificationList, NotificationProvider } from '../components/Notification';
import { Web3Provider } from '../utils/Web3Context';
import { useHotjar } from '../utils/hotjar';
import { useAmplitude } from '../utils/amplitude';
import { SWRProvider } from '@utils/SWRContext';
import '../styles/index.css';
import { MenuContextProvider } from '@utils/MenuContext';
import { ToastWrapper } from '@components/ToastWrapper';

function App(_ref) {
  var Component = _ref.Component,
      pageProps = _ref.pageProps;
  useHotjar();
  useAmplitude();
  return __jsx(React.Fragment, null, __jsx(ThemeProvider, {
    defaultTheme: "dark",
    attribute: "class",
    enableSystem: false,
    enableColorScheme: false
  }, __jsx(NotificationProvider, null, __jsx(Web3Provider, null, __jsx(ToastWrapper, null), __jsx(MenuContextProvider, null, __jsx(SWRProvider, null, __jsx(NextNprogress, {
    color: "#71FF64" // startPosition={0.3}
    // stopDelayMs={200}
    // height={3}
    // showOnShallow={true}

  }), __jsx(Component, pageProps), __jsx(NotificationList, null)))))), __jsx("div", {
    id: "notification-root"
  }));
}

export default App;