import { useMounted } from '@utils/hooks/useMounted';
import { useCustomTheme } from '@utils/theme';
import { ToastContainer } from 'react-toastify';

export interface ToastWrapperProps {}

export const ToastWrapper: React.FC<ToastWrapperProps> = props => {
  const mounted = useMounted();
  const { isDark } = useCustomTheme();
  return mounted ? (
    <ToastContainer
      theme={isDark ? 'dark' : 'light'}
      className="pointer-events-none"
      position="bottom-center"
      autoClose={0}
      hideProgressBar
      closeOnClick={false}
      closeButton={false}
      pauseOnFocusLoss
    />
  ) : null;
};
