import { useEffect } from 'react';
import amplitude from 'amplitude-js';
import { AMPLITUDE_API_KEY } from './config';

export const useAmplitude = () => {
  useEffect(() => {
    if (typeof window === 'undefined' || !AMPLITUDE_API_KEY) return;
    amplitude.getInstance().init(AMPLITUDE_API_KEY);
  }, []);
};

export const useLogPage = (page: string, data?: any) => {
  useEffect(() => {
    logPage(page, data);
  }, [data, page]);
};

export const identifyUser = (address?: string) => {
  if (typeof window === 'undefined' || !AMPLITUDE_API_KEY) return;
  if (!address) return;

  new amplitude.Identify().setOnce('$address', address);
  amplitude.getInstance().setUserId(address);
};

const logEvent = (event: string, data?: any) => {
  if (process.env.NODE_ENV === 'development') console.log(event, data);

  if (typeof window === 'undefined' || !AMPLITUDE_API_KEY) return;

  const amplitudeInstance = amplitude.getInstance();
  amplitudeInstance.logEvent(event, data);
};

// there are ways to automatically doing this using router but they don't really do what we want
function logPage(page: string, data?: any) {
  logEvent(`View ${page}`, data);
}

export function logConnectAddress() {
  logEvent('Connect Address');
}

export function logOpenNewAlertModal() {
  logEvent('Open New Alert Modal');
}

export function logCloseNewAlertModal() {
  logEvent('Close New Alert Modal');
}

export function logCreateNewAlert(data: any) {
  logEvent('Create New Alert', data);
}

export function logAccessFreeMainCTA() {
  logEvent('Click Access Free Main CTA');
}

export function logAccessDashboardCTA() {
  logEvent('Click Access Dashboard CTA');
}

export function logClickBuy(data: { label: string; price?: number; term?: number }) {
  logEvent('Click Buy', data);
}
