import React from 'react';

import { Transition } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { XCircleIcon } from '@heroicons/react/solid';
import { createNotificationContext } from 'react-notification-provider';

import { InPortal } from './InPortal';

const DURATION = 5_000;

export interface NotificationProps {
  message?: string;
  description: string | React.ReactNode;
  level: 'success' | 'error';
  duration?: number;
}

const { NotificationProvider, useNotificationQueue } =
  createNotificationContext<NotificationProps>();
export { NotificationProvider };

export const useNotification = () => {
  const { add, remove } = useNotificationQueue();

  const showNotification = React.useCallback(
    ({ level, message, description }: NotificationProps) => {
      const id = `${message}_${description}`;
      add(id, { level, message, description });
      setTimeout(() => remove(id), DURATION + 500);
    },
    [add, remove]
  );

  const showSuccessNotification = React.useCallback(
    ({ message, description }: Pick<NotificationProps, 'message' | 'description'>) => {
      showNotification({
        level: 'success',
        message: message || 'Success',
        description,
      });
    },
    [showNotification]
  );

  const showErrorNotification = React.useCallback(
    ({ message, description }: Pick<NotificationProps, 'message' | 'description'>) => {
      showNotification({
        level: 'error',
        message: message || 'Error',
        description,
      });
    },
    [showNotification]
  );

  return { showSuccessNotification, showErrorNotification };
};

export const Notification: React.FC<NotificationProps> = props => {
  const [isOpen, setIsOpen] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setIsOpen(false);
    }, props.duration || DURATION);
  }, [props.duration]);

  return (
    <InPortal id="notification-root">
      <div className="fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end z-50">
        <Transition
          show={isOpen}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="max-w-sm w-full bg-surface-light-mode dark:bg-surface-dark-mode bg-opacity-70 dark:bg-opacity-70 backdrop-filter backdrop-blur-lg shadow-lg rounded-lg pointer-events-auto"
        >
          <div className="rounded-lg focus:ring-1 ring-black dark:ring-white ring-opacity-5 overflow-hidden border border-gray-200 dark:border-gray-700">
            <div className="p-6">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  {props.level === 'success' ? (
                    <CheckCircleIcon className="h-6 w-6 text-green-400" />
                  ) : (
                    <XCircleIcon className="h-6 w-6 text-red-400" />
                  )}
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="font-grifter leading-7 text-xl text-black dark:text-white">
                    {props.message}
                  </p>
                  <p className="mt-2 leading-7 text-lg text-gray-750 dark:text-gray-200">
                    {props.description}
                  </p>
                </div>
                <div className="ml-4 flex-shrink-0 flex">
                  <button
                    className="inline-flex text-gray-600 hover:text-black dark:text-gray-300 dark:hover:text-white  focus:outline-none transition"
                    onClick={() => setIsOpen(false)}
                  >
                    <XIcon className="h-5 w-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </InPortal>
  );
};

export const NotificationList: React.FC<{}> = props => {
  const queue = useNotificationQueue();

  return (
    <div>
      {queue.entries.map(({ id, data }) => (
        <Notification
          key={id}
          message={data.message}
          description={data.description}
          level={data.level}
        />
      ))}
    </div>
  );
};
