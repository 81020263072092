import React from 'react';
import classNames from 'classnames';

export const Heading: React.FC<{ inline?: boolean }> = props => {
  const { inline } = props;
  return (
    <h1
      className={classNames('font-grifter break-words md:break-normal text-4xl xl:text-7xl', {
        // fixes safari and IPhones bg-clip bug
        inline,
      })}
    >
      {props.children}
    </h1>
  );
};

export const HeadingV2: React.FC<{ inline?: boolean }> = props => {
  const { inline } = props;
  return (
    <h1
      className={classNames('font-grifter break-words md:break-normal text-2xl xl:text-4xl', {
        // fixes safari and IPhones bg-clip bug
        inline,
      })}
    >
      {props.children}
    </h1>
  );
};

export const SubHeading: React.FC<{}> = props => {
  return (
    <h2 className="font-grifter text-2xl sm:text-3xl md:text-4xl font-bold">{props.children}</h2>
  );
};

export const SubHeading2: React.FC<{}> = props => {
  return <h3 className="font-grifter text-2xl font-bold capitalize">{props.children}</h3>;
};

export const SubHeading3: React.FC<{}> = props => {
  return <h3 className="font-grifter text-xl font-bold capitalize">{props.children}</h3>;
};

export const Description: React.FC<{}> = props => {
  return <div className="text-xl sm:text-2xl mt-6 pb-6 md:pb-0">{props.children}</div>;
};

export const Paragraph: React.FC<{}> = props => {
  return <div className="text-lg md:text-xl leading-8 font-normal">{props.children}</div>;
};
