import { ethers } from 'ethers';

export const LOGIN_MESSAGE = 'Exodia login';

export async function signMessage(options: {
  message?: string;
  signer: ethers.providers.JsonRpcSigner;
}) {
  const { message = LOGIN_MESSAGE, signer } = options;
  const signature = await signer.signMessage(message);
  const address = await signer.getAddress();

  return {
    message,
    signature,
    address,
  };
}

export function verifyMessage(options: {
  address: string;
  message?: string;
  signature: string;
}): boolean {
  const { address, message = LOGIN_MESSAGE, signature } = options;
  if (!signature) return false;
  try {
    const signerAddress = ethers.utils.verifyMessage(message, signature);
    return signerAddress.toLowerCase() === address.toLowerCase();
  } catch (err) {
    console.log(err);
    return false;
  }
}
