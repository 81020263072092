// Not the cleanest way of doing this, will improve soon

import { DISABLE_PREMIUM, WHITELISTED_CONTRACTS, DISCOUNT_CONTRACTS } from './config';
import catcABI from '../abi/catc.abi.json';

const whitelist = [
  '0xD514511E297d40A49F07A312c5342C9346A68b00', // Naor B
  '0xf5e20c261dFBba62EdEf37116cbf86cDBA6220Cb', // Tamir G
  '0xBcc48e02eD5b97CA855F282d1FfDef268Bb88A83', // L
  '0x8C9FcCEd4c9ec98Ced2EeC251E905E48Dbfeb2C5', // Anu
  '0x46b421bA99f0c3135c925a5D39fCA5d7830f2d82', // Ilan S
  '0x677257F094e0cDcA58BeAD00f403D7d1623272b8', // Jonathon T
  '0x53c5E348A32C2fcd38180e45EBD841376f59AaeB', // David Binance
  '0xa5D9Fcc53DA762907FED07A8c81E84601ECCC211', // Web3m
  '0x650899293E362D54163579789Fe20703cbF7400f', // Web3m
  '0xC54855c09F2E8b3430F81fc7b664ca6CE9ceC67D', // Whale Togather
  '0x8A4338b208c1760A6884bE10C9358DbF23B4E6af', // Whale Togather
  '0xf0c0bb7bD5E9114BFd4f785179c86FF2124aB2D0', // Whale Togather
  '0x7dbc793d7e740c455F6f41F1AB34861675b1e023', // Whale Togather
  '0xA40EE8cc12795f65EB9D43F8Fa27d27D8806a149', // Whale Togather
];

// Smart contract of projects that all of their holders are whitelisted for premium. Should have premium until 1.1.23,
export const whitelistedCollections = DISABLE_PREMIUM
  ? []
  : WHITELISTED_CONTRACTS.map(address => {
      return { address, abi: catcABI };
    });

// address of projects that all of their holders are entitled for a 40% discount on monthly or 6 month premium
export const discountCollections = DISABLE_PREMIUM
  ? []
  : DISCOUNT_CONTRACTS.map(address => {
      return { address, abi: catcABI };
    });

export const isWhitelistedManually = (address?: string): boolean => {
  return !!(
    DISABLE_PREMIUM ||
    (address &&
      whitelist.find(whitelistAddress => whitelistAddress.toLowerCase() === address.toLowerCase()))
  );
};
