import React, { useCallback, useEffect, useState } from 'react';
import { ethers } from 'ethers';
import Web3Modal from 'web3modal';
import WalletLink from 'walletlink';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { ExternalProvider } from '@ethersproject/providers';
import { useLocalStorage } from 'react-use';
import PURCHASE_CONTRACT from '../abi/Exodia.json';
import exodiaFireEmblemAbi from '../abi/ExodiaFireEmblem.json';
import { Modal } from '@components/Modal';
import { SubHeading } from '@components/Typography';
import { useNotification } from '@components/Notification';
import { msToSeconds, secondsToDays, isMobileDevice, prettyError } from './general';
import {
  INFURA_ID,
  NETWORK_NAME,
  PURCHASE_CONTRACT_ADDRESS,
  SUBSCRIPTION_ADDRESS,
  ONE_MONTH_SUBSCRIPTION_PRICE,
  SIX_MONTHS_SUBSCRIPTION_PRICE,
  PARTNERS_DISCOUNT_PRICE,
  FIRE_EMBLEM_CONTRACT_ADDRESS,
  THUNDER_EMBLEM_CONTRACT_ADDRESS,
  FROST_EMBLEM_CONTRACT_ADDRESS,
  FLOW_EMBLEM_CONTRACT_ADDRESS,
  GALAXY_EMBLEM_CONTRACT_ADDRESS,
  WHALE_TOGETHER_CONTRACT_ADDRESS,
} from './config';
import { whitelistedCollections, discountCollections } from './whitelist';
import { signMessage, verifyMessage } from './web3';
import { updateSignature } from './api';
import { openInNewTab } from '@utils/openInNewTab';
import { identifyUser } from './amplitude';
import { saveAddress } from '@sharemint/sdk';

const LIFETIME_TOKEN_ID = 1;

const ONE_MONTH_PREMIUM_DAYS = 30;
const SIX_MONTHS_PREMIUM_DAYS = 183;
const ONE_MONTH_SUBSCRIPTION_PRICE_DISCOUNT =
  ONE_MONTH_SUBSCRIPTION_PRICE * (1 - PARTNERS_DISCOUNT_PRICE);
const SIX_MONTHS_SUBSCRIPTION_PRICE_DISCOUNT =
  SIX_MONTHS_SUBSCRIPTION_PRICE * (1 - PARTNERS_DISCOUNT_PRICE);

const SPECIAL_ACCESS_TOKENS: {
  feature: keyof typeof defaultContextValue['premiumAccess'];
  contractAddress: string;
  abi: any;
}[] = [
  {
    feature: 'sniper',
    contractAddress: FIRE_EMBLEM_CONTRACT_ADDRESS,
    abi: exodiaFireEmblemAbi.abi,
  },
  {
    feature: 'sniper',
    contractAddress: WHALE_TOGETHER_CONTRACT_ADDRESS,
    abi: exodiaFireEmblemAbi.abi,
  },
  {
    feature: 'wallet',
    contractAddress: THUNDER_EMBLEM_CONTRACT_ADDRESS,
    abi: exodiaFireEmblemAbi.abi,
  },
  {
    feature: 'alerts',
    contractAddress: FROST_EMBLEM_CONTRACT_ADDRESS,
    abi: exodiaFireEmblemAbi.abi,
  },
  {
    feature: 'firehose',
    contractAddress: FLOW_EMBLEM_CONTRACT_ADDRESS,
    abi: exodiaFireEmblemAbi.abi,
  },
  {
    feature: 'dashboard',
    contractAddress: GALAXY_EMBLEM_CONTRACT_ADDRESS,
    abi: exodiaFireEmblemAbi.abi,
  },
];

const networks: { [key: string]: string } = {
  mainnet: '0x1', // 1
  ropsten: '0x3', // 3
  rinkeby: '0x4', // 4
  local: '0x539', // 1337
};
const chainId = networks[NETWORK_NAME];

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: INFURA_ID, // required
    },
  },
  'custom-walletlink': {
    display: {
      logo: 'https://play-lh.googleusercontent.com/PjoJoG27miSglVBXoXrxBSLveV6e3EeBPpNY55aiUUBM9Q1RCETKCOqdOkX2ZydqVf0',
      name: 'Coinbase',
      description: 'Connect to Coinbase Wallet (not Coinbase App)',
    },
    options: {
      appName: 'Exodia', // Your app name
      networkUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
      chainId,
    },
    package: WalletLink,
    connector: async (_: any, options: any) => {
      const { appName, networkUrl, chainId } = options;
      const walletLink = new WalletLink({ appName });
      const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
      await provider.enable();
      return provider;
    },
  },
  'custom-metamask': {
    display: {
      logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/128px-MetaMask_Fox.svg.png?20201112074605',
      name: 'MetaMask',
      description: 'Connect to your MetaMask Wallet',
    },
    package: true,
    connector: async () => {
      let provider = null;
      if (window?.ethereum) {
        const providers = window.ethereum.providers;
        // if there are no multiple injected providers available, return the injected provider
        provider = providers
          ? providers.find((p: ethers.providers.ExternalProvider) => p.isMetaMask)
          : window.ethereum; // <-- LOOK HERE
        try {
          await provider.request({ method: 'eth_requestAccounts' });
        } catch (error) {
          console.log('User rejected metamask connection');
        }
      } else {
        openInNewTab(
          isMobileDevice()
            ? 'https://metamask.app.link/dapp/exodia.io'
            : 'https://metamask.io/download.html'
        );
        return;
      }
      console.log('MetaMask provider', provider);
      return provider;
    },
  },
};

export type UserDetails = { address?: string; signature?: string };

interface Context {
  address?: string;
  isWalletConnecting: boolean;
  user: UserDetails;
  lifetimeTokenCount?: number;
  subscriptionStartDate?: number;
  partnerContractAddress?: string;
  isValidatingPremiumAccess: boolean;
  premiumAccess: {
    premium: boolean;
    sniper: boolean;
    firehose: boolean;
    wallet: boolean;
    alerts: boolean;
    dashboard: boolean;
  };
  onConnectWallet: () => Promise<void>;
  checkIfWalletConnected: () => Promise<void>;
  onLoadLifetimeCount: () => Promise<boolean>;
  onLoadAccess: () => Promise<boolean>;
  onPurchase: (price: number) => Promise<void>;
  onRefund: () => Promise<void>;
}

const defaultContextValue: Context = {
  address: undefined,
  isWalletConnecting: false,
  user: {},
  lifetimeTokenCount: undefined,
  premiumAccess: {
    premium: false,
    sniper: false,
    firehose: false,
    wallet: false,
    alerts: false,
    dashboard: false,
  },
  isValidatingPremiumAccess: false,
  onConnectWallet: async () => {},
  checkIfWalletConnected: async () => {},
  onLoadLifetimeCount: async () => false,
  onLoadAccess: async () => false,
  onPurchase: async () => {},
  onRefund: async () => {},
};

const web3Modal =
  typeof window === 'undefined'
    ? null
    : new Web3Modal({
        cacheProvider: false,
        disableInjectedProvider: true,
        providerOptions,
        network: NETWORK_NAME,
      });

export const Web3Context = React.createContext<Context>(defaultContextValue);

export const useWeb3Context = () => React.useContext(Web3Context);

export const Web3Provider: React.FC<{}> = ({ children }) => {
  const [address, setAddress] = useState<string | undefined>();
  const [isWalletConnecting, setIsWalletConnecting] = useState(false);
  const [isValidatingPremiumAccess, setIsValidatingPremiumAccess] = useState(false);

  // const [signedAddress, setSignedAddress] = useState<string | undefined>();
  const [user, setUser] = useState<UserDetails>({});
  const [lifetimeTokenCount, setLifetimeTokenCount] = useState<number | undefined>(undefined);
  // this can be true if either lifetime token count > 0 or if the user has whitelisted token (e.g addys)
  const [premiumAccess, setPremiumAccess] = useState<Context['premiumAccess']>({
    sniper: false,
    alerts: false,
    wallet: false,
    firehose: false,
    dashboard: false,
    premium: false,
  });
  const [partnerContractAddress, setPartnerContractAddress] = useState('');
  const [subscriptionStartDate, setSubscriptionStartDate] = useState<number | undefined>(undefined);

  const [localStorageSignature, setLocalStorageSignature] = useLocalStorage<string>('signature');
  const [localStorageAddress, setLocalStorageAddress] = useLocalStorage<string>('address');
  const setUserDetails = useCallback(
    ({ address, signature }: UserDetails) => {
      setUser({ address, signature });
      setLocalStorageSignature(signature);
      setLocalStorageAddress(address);
      updateSignature({ address, signature });
    },
    [setLocalStorageAddress, setLocalStorageSignature]
  );

  const [errorModalMessage, setErrorModalMessage] = useState('');

  useEffect(() => {
    identifyUser(address);
  }, [address]);

  const { showSuccessNotification } = useNotification();

  const closeErrorModal = useCallback(() => setErrorModalMessage(''), []);

  const onConnectWallet = useCallback(async () => {
    setIsWalletConnecting(true);
    const walletResponse = await connectWallet();
    setAddress(walletResponse?.address);
    setIsWalletConnecting(false);
    if (walletResponse?.address) {
      saveAddress({ slug: 'exodia-premium', address: walletResponse.address });
    }
  }, []);

  const checkIfWalletConnected = useCallback(async () => {
    const connectedAddress = await getConnectedAddress();
    if (connectedAddress.address) {
      setAddress(connectedAddress.address);
    }
  }, []);

  const userHasActiveSubscription = async (userAddress: string): Promise<boolean> => {
    const etherscanProvider = new ethers.providers.EtherscanProvider();
    const history = await etherscanProvider.getHistory(userAddress!);

    const oneMonthSubscriptionTxs = history
      .filter(
        (tx: any) =>
          tx.from &&
          tx.from.toLowerCase() === userAddress?.toLowerCase() &&
          tx.to &&
          tx.to.toLowerCase() === SUBSCRIPTION_ADDRESS.toLowerCase() &&
          tx.value &&
          Number(ethers.utils.formatEther(tx.value)) ==
            (ONE_MONTH_SUBSCRIPTION_PRICE || ONE_MONTH_SUBSCRIPTION_PRICE_DISCOUNT)
      )
      .sort((a: any, b: any) => b.timestamp - a.timestamp);

    const sixMonthsSubscriptionTxs = history
      .filter(
        (tx: any) =>
          tx.from &&
          tx.from.toLowerCase() === userAddress?.toLowerCase() &&
          tx.to &&
          tx.to.toLowerCase() === SUBSCRIPTION_ADDRESS.toLowerCase() &&
          tx.value &&
          Number(ethers.utils.formatEther(tx.value)) ==
            (SIX_MONTHS_SUBSCRIPTION_PRICE || SIX_MONTHS_SUBSCRIPTION_PRICE_DISCOUNT)
      )
      .sort((a: any, b: any) => b.timestamp - a.timestamp);

    const txsToSubscriptionAddress = sixMonthsSubscriptionTxs.concat(oneMonthSubscriptionTxs);
    const latestSubscriptionTx = txsToSubscriptionAddress[0];
    const subscriptionDays =
      latestSubscriptionTx === sixMonthsSubscriptionTxs[0]
        ? SIX_MONTHS_PREMIUM_DAYS
        : ONE_MONTH_PREMIUM_DAYS;

    const hasActiveSubscription =
      latestSubscriptionTx &&
      secondsToDays(msToSeconds(Date.now()) - (latestSubscriptionTx.timestamp ?? 0)) <
        subscriptionDays;

    if (hasActiveSubscription) {
      setSubscriptionStartDate(latestSubscriptionTx.timestamp);
    }

    return hasActiveSubscription;
  };

  const onPurchase = useCallback(
    async (price: number) => {
      try {
        await onConnectWallet();
        const { signer, discountContracts } = await connectWeb3();
        const userAddress = await signer?.getAddress();

        let holdsPartnersNFT = false;

        for (const discountContract of discountContracts || []) {
          const tokensThatProvidesDiscount = await discountContract.balanceOf(userAddress);
          if (tokensThatProvidesDiscount.toNumber() > 0) {
            setPartnerContractAddress(discountContract.address);
            holdsPartnersNFT = true;
            break;
          }
        }

        // This line should be removed when user will be connected from the start instead of needed to connect again
        const finalPrice = !holdsPartnersNFT ? price : price * (1 - PARTNERS_DISCOUNT_PRICE);

        const transaction = await signer?.sendTransaction({
          to: SUBSCRIPTION_ADDRESS,
          value: ethers.utils.parseUnits(finalPrice.toString(), 'ether').toHexString(),
        });

        showSuccessNotification({
          message: 'Pending',
          description:
            'The transaction is processing. This can take a while depending on how congested the blockchain is.',
        });

        await transaction?.wait();

        showSuccessNotification({
          message: 'Success',
          description: `Congrats! You've been upgraded to premium! Don't forget to join our Discord chat too!`,
        });
      } catch (error: any) {
        console.log(prettyError(error));

        if (error.code === 'INSUFFICIENT_FUNDS')
          setErrorModalMessage(`You do not have sufficient funds to complete the purchase.`);
      }
    },
    [onConnectWallet, showSuccessNotification]
  );

  // NFT minting
  // const onPurchase = useCallback(
  //   async (card?: { price: number; tokenId: number; amount?: number }) => {
  //     const walletResponse = await onConnectWallet();
  //     const { contract } = await connectWeb3();

  //     if (!contract) return onConnectWallet();

  //     try {
  //       const tokenId = card?.tokenId || LIFETIME_TOKEN_ID;
  //       const nftPrice = card?.price || 0.15;
  //       const amount = card?.amount || 1;

  //       const price = ethers.utils.parseUnits((nftPrice * amount).toString(), 'ether');
  //       const transaction = await contract.buy(tokenId, amount, { value: price });

  //       showSuccessNotification({
  //         message: 'Pending',
  //         description:
  //           'The transaction is processing. This can take a while depending on how congested the blockchain is.',
  //       });

  //       await transaction.wait();

  //       showSuccessNotification({
  //         message: 'Success',
  //         description: `Congrats! You've been upgraded to premium! Don't forget to join our premium Discord chat too!`,
  //       });
  //     } catch (error: any) {
  //       console.log(prettyError(error));

  //       if (error.code === 'INSUFFICIENT_FUNDS')
  //         setErrorModalMessage(`You do not have sufficient funds to complete the purchase.`);
  //       else setErrorModalMessage(`There was an error completing the purchase :(`);
  //     }
  //   },
  //   [onConnectWallet, showSuccessNotification]
  // );

  const onLoadLifetimeCount = useCallback(async () => {
    try {
      setIsValidatingPremiumAccess(true);
      await onConnectWallet();
      const { contract, signer, whitelistedContracts } = await connectWeb3();

      if (!contract) {
        onConnectWallet();
        return false;
      }

      const resLifetime = await contract.balanceOf(address, LIFETIME_TOKEN_ID);
      const count = resLifetime.toNumber();
      setLifetimeTokenCount(count);
      const hasLifetimeToken = count > 0;
      if (hasLifetimeToken) {
        setPremiumAccess(prevState => ({ ...prevState, premium: true }));
        return true;
      }
      const hasActiveSubscription = address && (await userHasActiveSubscription(address));
      if (hasActiveSubscription) {
        setPremiumAccess(prevState => ({ ...prevState, premium: true }));
        return true;
      }

      let hasPremium = false;

      for (const whitelistedContract of whitelistedContracts || []) {
        const whitelistedTokens = await whitelistedContract.contract.balanceOf(address);
        if (whitelistedTokens.toNumber() >= whitelistedContract.numberOfTokensNeeded) {
          hasPremium = true;
          setPremiumAccess(prevState => ({ ...prevState, premium: true }));
          setPartnerContractAddress(whitelistedContract.contract.address);
          break;
        }
      }
      setPremiumAccess(prevState => ({ ...prevState, premium: hasPremium }));
      return hasPremium;
    } catch (e) {
      console.log(e);
      return false;
    } finally {
      setIsValidatingPremiumAccess(false);
    }
  }, [onConnectWallet, address]);

  const onLoadAccess = useCallback(async () => {
    try {
      await onConnectWallet();
      const { contract, signer } = await connectWeb3();

      if (!contract || !signer) {
        await onConnectWallet();
        return false;
      }

      let signature: string;
      let address: string;

      if (localStorageSignature && localStorageAddress) {
        signature = localStorageSignature;
        address = localStorageAddress;
      } else {
        const result = await signMessage({ signer });
        signature = result.signature;
        address = result.address;
      }
      setUserDetails({ address, signature });

      const isSigned = verifyMessage({ address, signature });

      if (!address) return false;

      const balance = await contract.balanceOf(address, LIFETIME_TOKEN_ID);
      const tokens = +balance.toString();

      setLifetimeTokenCount(tokens);
      setPremiumAccess(prevState => ({ ...prevState, premium: tokens > 0 }));

      return isSigned && tokens > 0;
    } catch (e) {
      console.log(e);
      return false;
    }
  }, [localStorageAddress, localStorageSignature, onConnectWallet, setUserDetails]);

  const onRefund = useCallback(async () => {
    const walletResponse = await onConnectWallet();
    const { contract } = await connectWeb3();

    if (!contract) return onConnectWallet();

    try {
      const transaction = await contract.refund(LIFETIME_TOKEN_ID, 1);

      showSuccessNotification({
        message: 'Pending',
        description:
          'The transaction is processing. This can take a while depending on how congested the blockchain is.',
      });

      await transaction.wait();

      showSuccessNotification({
        message: 'Success',
        description: `Your eth has been refunded!`,
      });

      onLoadLifetimeCount();
    } catch (error: any) {
      console.log(prettyError(error));

      setErrorModalMessage(
        `There was an error completing the refund 😔\n\n${error.error?.message || ''}`
      );
    }
  }, [onConnectWallet, onLoadLifetimeCount, showSuccessNotification]);

  // TODO this isn't set up well at all (and isn't secure)
  // someone could buy the token, and then sell it on for example, and would still have access
  const onLoadLocalStorageAccess = useCallback(async () => {
    if (localStorageAddress && localStorageSignature) {
      const signed = verifyMessage({
        address: localStorageAddress,
        signature: localStorageSignature,
      });
      if (signed) setUser({ address: localStorageAddress, signature: localStorageSignature });
    }
  }, [localStorageAddress, localStorageSignature]);

  const checkFeatureAccessToken = useCallback(
    async (
      features: { feature: keyof typeof premiumAccess; contractAddress: string; abi: any }[]
    ) => {
      if (!address) {
        setPremiumAccess(defaultContextValue['premiumAccess']);
        return;
      }
      try {
        setIsValidatingPremiumAccess(true);
        const { signer } = await getProviderAndSigner();
        for (const feature of features) {
          if (!feature.contractAddress) continue;
          if (premiumAccess[`${feature.feature}`]) continue;
          try {
            const accessContract = new ethers.Contract(
              feature.contractAddress,
              feature.abi,
              signer
            );
            const tokenCount = await accessContract.balanceOf(address);
            setPremiumAccess(prevState => ({
              ...prevState,
              [feature.feature]: prevState[feature.feature] || +tokenCount > 0,
            }));
          } catch (error) {
            console.log(error);
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsValidatingPremiumAccess(false);
      }
    },
    [address, premiumAccess]
  );

  React.useEffect(() => {
    onLoadLocalStorageAccess();
  }, [onLoadLocalStorageAccess]);

  // on disconnect from metamask
  useEffect(() => {
    const onAccountChanged = (accounts: Array<string>) => {
      if (accounts.length) {
        setAddress(accounts[0]);
      } else {
        setAddress(undefined);
      }
    };

    if (window.ethereum) {
      window.ethereum.on('accountsChanged', onAccountChanged);
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', onAccountChanged);
      }
    };
  }, []);

  useEffect(() => {
    if (!address) {
      setPremiumAccess(defaultContextValue['premiumAccess']);
      return;
    }
    checkFeatureAccessToken(SPECIAL_ACCESS_TOKENS);
    onLoadLifetimeCount();
  }, [address, checkFeatureAccessToken, onLoadLifetimeCount]);

  return (
    <Web3Context.Provider
      value={{
        address,
        isWalletConnecting,
        user,
        lifetimeTokenCount,
        premiumAccess,
        subscriptionStartDate,
        partnerContractAddress,
        onConnectWallet,
        checkIfWalletConnected,
        onPurchase,
        onLoadLifetimeCount,
        isValidatingPremiumAccess,
        onLoadAccess,
        onRefund,
      }}
    >
      {children}
      {errorModalMessage && (
        <Modal hideModal={closeErrorModal}>
          <div className="max-w-5xl p-6 flex flex-col items-center">
            <SubHeading>Error</SubHeading>
            <div className="text-lg mt-6 whitespace-pre-line">{errorModalMessage}</div>
          </div>
        </Modal>
      )}
    </Web3Context.Provider>
  );
};

const connectWallet = async (): Promise<{
  address: string | undefined;
  provider: ethers.providers.Web3Provider | undefined;
}> => {
  if (typeof window === 'undefined') return { address: undefined, provider: undefined };
  const ethereum: ExternalProvider | undefined = window.ethereum;
  let provider = ethereum ? new ethers.providers.Web3Provider(ethereum) : undefined;
  let accounts = (await provider?.listAccounts()) || [];
  if (!provider || !accounts.length) {
    web3Modal?.clearCachedProvider();
    provider = new ethers.providers.Web3Provider(await web3Modal?.connect());
    accounts = await provider.listAccounts();
  }
  const address = accounts[0];
  return { address, provider };
};

const getConnectedAddress = async (): Promise<{
  address: string | undefined;
}> => {
  if (window.ethereum) {
    try {
      const addresses = await window.ethereum.request({ method: 'eth_accounts' });

      // await window.ethereum.request({
      //   method: 'wallet_switchEthereumChain',
      //   params: [{ chainId }],
      // });

      return { address: addresses[0] };
    } catch (err) {}
  }
  return { address: undefined };
};

async function connectWeb3(): Promise<{
  contract?: ethers.Contract;
  signer?: ethers.providers.JsonRpcSigner;
  whitelistedContracts?: { contract: ethers.Contract; numberOfTokensNeeded: number }[];
  discountContracts?: ethers.Contract[];
}> {
  const { signer } = await getProviderAndSigner();
  const contract = new ethers.Contract(PURCHASE_CONTRACT_ADDRESS, PURCHASE_CONTRACT.abi, signer);

  // special whitelisted contracts
  const pixelPuppersAddress = '0x01662b3dd5c556aecbbd5efcc809ef22026cac26';
  const whaleTogetherAddress = '0x417737d49a175d62625154262d8569d3890425ae';

  const whitelistedContracts = whitelistedCollections.map((whitelistedCollection: any) => {
    const { address, abi } = whitelistedCollection;
    const numberOfTokensNeeded =
      address === pixelPuppersAddress ? 8 : address === whaleTogetherAddress ? 3 : 1;
    return {
      contract: new ethers.Contract(address, abi, signer),
      numberOfTokensNeeded,
    };
  });

  const discountContracts = discountCollections.map(
    (discountCollection: any) =>
      new ethers.Contract(discountCollection.address, discountCollection.abi, signer)
  );

  return { contract, signer, whitelistedContracts, discountContracts };
}

export async function getProviderAndSigner() {
  const { provider } = await connectWallet();
  return { provider, signer: provider?.getSigner() } as const;
}

export function useLatestBlock() {
  const [block, setBlock] = useState<number>();

  useEffect(() => {
    const getBlock = async () => {
      const b = await ethers.providers.getDefaultProvider().getBlockNumber();
      setBlock(b);
    };

    getBlock();
  }, []);

  return block;
}
